import { Visibility, VisibilityOff } from '@mui/icons-material';
import EmailIcon from '@mui/icons-material/Email';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { isValidEmail } from '../../resources/utils';
import { hideSignupError } from '../../store/actions/user';
import { ApplicationState, StoreDispatch } from '../../store/types';
import { SignupError } from '../../types/common';
import Button from '../common/button';

// import TextField from '../common/textfield';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    dispatch: StoreDispatch;
    onSubmit: (e: any) => void;
}

type Props = IProps & WrappedComponentProps & ReduxProps;

interface State {
    firstName?: string;
    lastName?: string;
    birthday?: string;
    email?: string;
    password?: string;
    errors?: SignupError;
    loading?: boolean;
    showPassword: boolean;
    repeatEmail?: string;
}

function toggleStateKey<T extends keyof State>(key: T, state: State, value: any): Pick<State, T> {
    if (value !== undefined) {
        return { [key]: value } as Pick<State, T>;
    }
    if (typeof state[key] === 'boolean') {
        return { [key]: !state[key] } as Pick<State, T>;
    }
    throw new Error(`Property ${key} is not a boolean`);
}

class SignupForm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            firstName: undefined,
            lastName: undefined,
            birthday: undefined,
            email: undefined,
            repeatEmail: undefined,
            showPassword: false,
            password: undefined,
            errors: {
                firstName: undefined,
                lastName: undefined,
                birthday: undefined,
                email: undefined,
                password: undefined,
            },
            loading: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkInputs = this.checkInputs.bind(this);
    }

    componentDidUpdate() {
        const { isErrorVisible } = this.props;
        const { loading } = this.state;
        if (isErrorVisible && loading !== false) {
            this.setState({ loading: false });
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(hideSignupError());
    }

    handleFocus(name: any) {
        const { dispatch } = this.props;
        dispatch(hideSignupError());
        this.setState((prevState) => ({ errors: { ...prevState.errors, [name]: null } }));
    }

    handleChange(event: any, name: any) {
        this.setState((state) => toggleStateKey(name as keyof State, state, event.target.value));

        // this.setState({ [name]: event.target.value });
    }

    handleSubmit() {
        const { onSubmit } = this.props;
        if (!this.checkInputs()) {
            this.setState({ loading: true });
            onSubmit(this.state);
        }
    }

    onKeyDown = (event: any) => {
        if (event.key === 'Enter') this.handleSubmit();
    };

    checkInputs() {
        const { intl } = this.props;
        const {
            email, password, firstName, lastName, repeatEmail
        } = this.state;
        const errors: SignupError = {
            firstName: undefined,
            lastName: undefined,
            email: undefined,
            repeatEmail: undefined,
            birthday: undefined,
            password: undefined,
        };

        if (!email || email.length <= 0) {
            errors.email = intl.formatMessage({ id: 'login.emailRequired' });
        } else if (!isValidEmail(email)) {
            errors.email = intl.formatMessage({ id: 'login.emailNotValid' });
        } else if (!repeatEmail || email.localeCompare(repeatEmail) !== 0) {
            errors.repeatEmail = intl.formatMessage({ id: 'login.emailNotSame' });
        }

        if (!firstName || firstName.length <= 0) {
            errors.firstName = intl.formatMessage({ id: 'login.firstNameRequired' });
        }
        if (!lastName || lastName.length <= 0) {
            errors.lastName = intl.formatMessage({ id: 'login.lastNameRequired' });
        }
        if (!password || password.length <= 0) {
            errors.password = intl.formatMessage({ id: 'login.passwordRequired' });
        } else if (password.length < 8) {
            errors.password = intl.formatMessage({ id: 'login.passwordMinimumCharacters' });
        }

        this.setState({ errors });

        return errors.email
            || errors.password
            || errors.email
            || errors.password
            || errors.birthday
            || errors.lastName
            || errors.firstName;
    }

    render() {
        const { intl, isErrorVisible, errorText } = this.props;
        const {
            email, firstName, lastName, password, loading, errors, repeatEmail, showPassword
        } = this.state;

        const parsedErrorMessage = errorText ? JSON.parse(errorText) : undefined;

        return (
            /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
            <div className="__signup-form" onKeyDown={this.onKeyDown}>
                <span className="__signup-textfield-container">
                    <TextField
                        style={{ width: '100%' }}
                        variant="outlined"
                        value={firstName}
                        error={(errors && errors.firstName != null && errors.firstName?.length > 0)}
                        helperText={errors && errors.firstName != null && errors.firstName}
                        name="firstName"
                        type="text"
                        sx={{
                            '& .MuiFormLabel-root': {
                                color: 'var(--font-input-color)'
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--font-input-color)'
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--font-input-color)'
                            },
                            '& .MuiSvgIcon-root': {
                                color: 'var(--font-input-color)'
                            }
                        }}
                        label={intl.formatMessage({ id: 'firstname' })}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <PersonOutlineIcon />
                                </InputAdornment>
                            ),
                        }}
                        onFocus={() => this.handleFocus('firstName')}
                        onChange={(value) => this.handleChange(value, 'firstName')}
                    />
                </span>
                <span className="__signup-textfield-container">
                    <TextField
                        style={{ width: '100%' }}
                        variant="outlined"
                        value={lastName}
                        error={errors && errors.lastName != null && errors.lastName?.length > 0}
                        helperText={errors && errors.lastName != null && errors.lastName}
                        name="lastName"
                        type="text"
                        sx={{
                            '& .MuiFormLabel-root': {
                                color: 'var(--font-input-color)'
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--font-input-color)'
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--font-input-color)'
                            },
                            '& .MuiSvgIcon-root': {
                                color: 'var(--font-input-color)'
                            }
                        }}
                        id="not3"
                        label={intl.formatMessage({ id: 'lastname' })}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <PersonOutlineIcon />
                                </InputAdornment>
                            ),
                        }}
                        onFocus={() => this.handleFocus('lastName')}
                        onChange={(value) => this.handleChange(value, 'lastName')}
                    />
                </span>
                <span className="__signup-textfield-container">
                    <TextField
                        style={{ width: '100%' }}
                        variant="outlined"
                        value={email}
                        error={errors && errors.email != null && errors.email?.length > 0}
                        helperText={errors && errors.email != null && errors.email}
                        name="not1"
                        type="email"
                        sx={{
                            '& .MuiFormLabel-root': {
                                color: 'var(--font-input-color)'
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--font-input-color)'
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--font-input-color)'
                            },
                            '& .MuiSvgIcon-root': {
                                color: 'var(--font-input-color)'
                            }
                        }}
                        label={intl.formatMessage({ id: 'email' })}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <EmailIcon />
                                </InputAdornment>
                            ),
                        }}
                        onFocus={() => this.handleFocus('email')}
                        onChange={(value) => this.handleChange(value, 'email')}
                    />
                </span>
                <span className="__signup-textfield-container">
                    <TextField
                        style={{ width: '100%' }}
                        variant="outlined"
                        value={repeatEmail}
                        sx={{
                            '& .MuiFormLabel-root': {
                                color: 'var(--font-input-color)'
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--font-input-color)'
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--font-input-color)'
                            },
                            '& .MuiSvgIcon-root': {
                                color: 'var(--font-input-color)'
                            }
                        }}
                        error={errors && errors.repeatEmail != null && errors.repeatEmail?.length > 0}
                        helperText={errors && errors.repeatEmail != null && errors.repeatEmail}
                        name="reapeatEmail"
                        type="email"
                        label={intl.formatMessage({ id: 'repeatEmail' })}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <EmailIcon />
                                </InputAdornment>
                            ),
                        }}
                        onFocus={() => this.handleFocus('repeatEmail')}
                        onChange={(value) => this.handleChange(value, 'repeatEmail')}
                    />
                </span>
                <span className="__signup-textfield-container">
                    <TextField
                        style={{ width: '100%' }}
                        variant="outlined"
                        value={password}
                        error={errors && errors.password != null && errors.password?.length > 0}
                        helperText={errors && errors.password != null && errors.password}
                        type={showPassword ? 'text' : 'password'}
                        label={intl.formatMessage({ id: 'password' })}
                        sx={{
                            '& .MuiFormLabel-root': {
                                color: 'var(--font-input-color)'
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--font-input-color)'
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--font-input-color)'
                            },
                            '& .MuiSvgIcon-root': {
                                color: 'var(--font-input-color)'
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        style={{ paddingLeft: '0px', paddingRight: '0px' }}
                                        aria-label="toggle password visibility"
                                        onClick={() => this.setState((prevState) => ({ showPassword: !prevState.showPassword }))}
                                        onMouseDown={(e) => e?.preventDefault()}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        onFocus={() => this.handleFocus('password')}
                        onChange={(value) => this.handleChange(value, 'password')}
                    />
                </span>
                {
                    isErrorVisible && parsedErrorMessage
                        && parsedErrorMessage.code === 'ClientRegister-EmailExist-01'
                        ? <span className="__signup-error">{parsedErrorMessage.message}</span>
                        : <FormattedMessage id="login.registerFailed" />
                }
                <span className="__signup-button-container">
                    <Button onClick={this.handleSubmit} loading={loading}>
                        <FormattedMessage id="login.signup" />
                    </Button>
                </span>
            </div>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    isErrorVisible: store.user.isErrorVisible,
    errorText: store.user.errorText,
});
const connector = connect(mapStateToProps);

export default injectIntl(connector(SignupForm));
