import CloseIcon from '@mui/icons-material/Close';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { Box } from '@mui/material';
import React from 'react';
import { withRouter } from 'react-router';
import '../../resources/css/snowli-ai.css';
import {
    getSnowliGroupKeyForSchool
} from '../../resources/utils';
import withIsMobile from '../../resources/withIsMobile';
import { RouterProps } from '../../types/generalTypes';
// import Button from '../common/button';

const START_OPENED = false;

interface IProps {
    isMobile?: boolean;
}
interface State {
    school: any;
    isChatOpened: boolean;
    snowliBaseUrl: string;
}
type Props = IProps & RouterProps;

class SnowliAI extends React.Component<Props, State> {
    private static openCloseChat = () => {
        const iframe = document.getElementById('snowli-widget');
        if (iframe) {
            if (iframe.classList.contains('opened')) {
                iframe.classList.remove('opened');
                setTimeout(() => {
                    iframe.classList.remove('display-block');
                    iframe.classList.add('display-none');
                }, 300);
            } else {
                iframe.classList.remove('display-none');
                iframe.classList.add('display-block');
                setTimeout(() => {
                    iframe.classList.add('opened');
                }, 10);
            }
        }
    };

    constructor(props: Props) {
        super(props);
        let BASE_URL = process.env.REACT_APP_FRONTEND_SNOWLI_URL;
        if (!BASE_URL) {
            BASE_URL = 'https://chat.snowli.ai';
        }
        this.state = {
            school: getSnowliGroupKeyForSchool(),
            isChatOpened: START_OPENED && !props.isMobile,
            snowliBaseUrl: BASE_URL
        };
    }

    componentDidMount() {
        const { isMobile } = this.props;
        if (START_OPENED && !isMobile) {
            SnowliAI.openCloseChat();
        }
    }

    // Had to do it this way because the animation would break otherwise
    componentDidUpdate(prevProps: Props, prevState: State) {
        const { isChatOpened } = this.state;
        if (prevState.isChatOpened !== isChatOpened) {
            SnowliAI.openCloseChat();
        }
    }

    handleChat = () => {
        this.setState((state) => ({
            isChatOpened: !state.isChatOpened
        }));
    };

    render() {
        const { school, isChatOpened, snowliBaseUrl } = this.state;
        const { isMobile, match } = this.props;
        if (!school) {
            return null;
        }

        return (
            <div className={`snowli-iframe ${isMobile ? 'mobile' : ''} ${isChatOpened ? 'opened' : ''}`}>
                <iframe title="Snowli AI" id="snowli-widget" className={`display-none ${isMobile ? 'mobile' : ''}`} width={`${isMobile ? '100%' : 350}`} style={{ height: isMobile ? '70vh' : 450, border: 0 }} src={`${snowliBaseUrl}/${match.params.lang}/widget/chat/${school}?mobile=${isMobile}`} />

                {(isMobile && isChatOpened)
                    ? (
                        <Box
                            style={{
                                position: 'absolute', top: 7, right: 7, cursor: 'pointer'
                            }}
                            onClick={this.handleChat}
                        >
                            <CloseIcon style={{ color: 'white' }} />
                        </Box>
                    )
                    : (
                        <button type="button" className={`chat-button ${isChatOpened ? 'opened' : ''} ${isMobile ? 'mobile' : ''}`} onClick={this.handleChat} style={{ padding: '0px', position: 'relative' }}>
                            <Box className="loading-button-waves">
                                <Box className="loading-button-wave" />
                                <Box className="loading-button-wave" />
                                {/* <Box className='loading-button-wave' /> */}
                            </Box>
                            <Box className="chat-button-content">
                                <QuestionAnswerIcon />
                            </Box>
                            <Box className="chat-button-content" style={{ position: 'absolute', top: 0 }}>
                                <QuestionAnswerIcon />
                            </Box>
                        </button>
                    )}

                {(!isMobile && isChatOpened)
                    ? (
                        <Box
                            style={{
                                position: 'absolute', top: 7, right: 7, cursor: 'pointer'
                            }}
                            onClick={this.handleChat}
                        >
                            <CloseIcon style={{ color: 'white' }} />
                        </Box>
                    )
                    : (
                        null
                    )}
            </div>
        );
    }
}

export default withIsMobile<IProps>(withRouter(SnowliAI));
